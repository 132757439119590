import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    rosevilleImage: file(relativePath: { eq: "roseville.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function RosevillePage({ data }) {
  return (
    <MainLayout
      title="Professional Locksmith Services | 916-995-0135 | Roseville, CA"
      description="If you are locked out or have a lost key near Roseville, CA, we are the emergency locksmith service for you! Licensed technicians, Fast response & open 24/7."
      offset
    >
      <ContactHeading background={data.rosevilleImage.childImageSharp.fluid}>
        <h1 className="text-center">Roseville Locksmith</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Roseville Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2>Leading Locksmith in Roseville, CA</h2>
        <p className="indent mb-0">
          Swift Locksmith is your local, trusted locksmith providing reliable,
          quick support and complete security to the community of Roseville, CA
          and the nearby areas. We have got a very skilled group of locksmiths
          to address all sorts of lock and key issues economically and bring
          safety in your home, vehicle or business. Our extensive range of
          services include commercial, auto, and residential locksmith services
          in Roseville, CA that may be customized to fit your needs and budget,
          making your life easier.
        </p>
      </Container>
      <section>
        <div className="background-content text-white bg-dark">
          <Container>
            <h2>What We Offer</h2>
            <h3 className="text-primary mb-2">
              Residential Locksmith Services in Roseville, CA
            </h3>
            <Row>
              <Col xs={12} md={6} lg={5}>
                <ul className="mb-0">
                  <li>24/7 Emergency Lockout</li>
                  <li>Door Knob Lock Installation</li>
                  <li>Whole House Rekey</li>
                  <li>Lock Repair & Maintenance</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul>
                  <li>High Security Locks</li>
                  <li>Duplicate House Keys</li>
                  <li>On-Site Key Extraction</li>
                  <li>Master Key Systems</li>
                </ul>
              </Col>
            </Row>
            <h3 className="text-primary mb-2">
              Automotive Locksmith Services in Roseville, CA
            </h3>
            <Row>
              <Col xs={12} md={6} lg={5} className="mb-0">
                <ul className="mb-0">
                  <li>24/7 Emergency Lockout</li>
                  <li>Broken Key Extraction</li>
                  <li>Ignition Rekey</li>
                  <li>Laser Cut Keys</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul>
                  <li>Chip Keys Cut Services</li>
                  <li>Remote Programming</li>
                  <li>Lost Car Key Replacement</li>
                  <li>Replace Keyless Entry Remotes</li>
                </ul>
              </Col>
            </Row>
            <h3 className="text-primary mb-2">
              Commercial Locksmith Services in Roseville, CA
            </h3>
            <Row>
              <Col xs={12} md={6} lg={5} className="mb-0">
                <ul className="mb-0">
                  <li>24/7 Emergency Lockout</li>
                  <li>Business Rekey</li>
                  <li>Locks & Exit Device Repairs</li>
                  <li>Master Key System Installation</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul>
                  <li>Keyless Access System</li>
                  <li>Aluminum Door Lock Alignment & Installation</li>
                  <li>On-site Key Duplication</li>
                  <li>Deadbolts, High Security Deadbolts</li>
                </ul>
              </Col>
            </Row>
            <h3 className="text-primary mb-2">
              Emergency Locksmith Services in Roseville, CA
            </h3>
            <Row>
              <Col xs={12} md={6} lg={5} className="mb-0">
                <ul className="mb-0">
                  <li>Emergency Rekey Services</li>
                  <li>Car,House, Business Lockouts</li>
                  <li>Emergency Lock Repair Services</li>
                  <li>Bedroom,Storage, Garage Lockouts</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul className="mb-0">
                  <li>Mail Box Lockouts</li>
                  <li>Replacement of Lost Car Keys</li>
                  <li>Broken Key Extraction</li>
                  <li>Duplicate Keys</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="pb-0 pb-sm-5 mb-md-5">
        <h2>Reliable, Affordable Locksmith in Roseville, CA</h2>
        <Row>
          <Col xs={12} lg={{ span: 8, order: 2 }} xl={7}>
            <p className="indent">
              What sets Swift Locksmith apart from the competition is our
              competitive pricing, professionalism, and enthusiasm for this
              industry. We're always pleased to assist you with any of your
              locksmith needs whether you need help with a car lockout or
              perhaps you need to make sure that your home security is at its
              maximum level.
            </p>
            <p className="indent">
              We pride ourselves on a commitment to doing honest business the
              proper way and offer emergency locksmith services in the
              Roseville, CA area. Trusting us to get you back into your home or
              perhaps double checking your current security system is a
              privilege we do not take lightly. All of Swift Locksmith
              technicians are licensed, highly trained, and are held to a
              remarkably high standard of professionalism.
            </p>
            <p className="indent">
              Swift Locksmith has been serving Roseville, CA for more than ten
              years and we are able to fix all of your residential, automotive
              or commercial needs. We cover locksmith services such as lockouts,
              repair and replace locks, re-key locks, and provide new car keys.
              We're here to offer you high quality and fast locksmith services
              in Roseville, and the surrounding areas. Whatever your locksmith
              need is, give us a call now and the our expert team will gladly
              help you.
            </p>
          </Col>
          <Col xs={12} lg={4} xl={5} className="col-sm-pad">
            <Img
              fluid={data.locksmithWithCarImage.childImageSharp.fluid}
              alt="locksmith-with-car"
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default RosevillePage
